import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/test',
    name: 'Testler',
    component: () => import('../views/Test.vue')
  },
  {
    path: '/exam',
    name: 'Sınav',
    component: () => import('../views/Exam.vue')
  },
  {
    path: '/report',
    name: 'Rapor',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/candidate',
    name: 'Adaylar',
    component: () => import('../views/Candidate.vue')
  },
  {
    path: '/contact',
    name: 'İletişim',
    component: () => import('../views/Contact.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  const token = localStorage.token;

  if (to.name === 'Login' && token) {
    router.push({ path: '/', query: { to: to.path } });
  }

  if (to.name !== 'Login' && !token) {
    router.push({ path: '/login', query: { to: to.path } });
  }

  return next();
});

// For splash screen loading. To be implemented.
router.afterEach(() => {
  const appLoader = document.getElementById('app-loader');
  if (appLoader) {
    appLoader.style.display = 'none';
  }
});

export default router;
