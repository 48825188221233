import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#004040',
        secondary: '#00bcd4',
        accent: '#9c27b0',
        error: '#e91e63',
        warning: '#ffc107',
        info: '#2196f3',
        success: '#8bc34a',
        content: '#fff',
        tabber: '#fffbe6'
      },
      dark: {
        primary: '#004040',
        secondary: '#00bcd4',
        accent: '#9c27b0',
        error: '#e91e63',
        warning: '#ffc107',
        info: '#2196f3',
        success: '#8bc34a',
        content: '#fff'
      }
    }
  }
});
