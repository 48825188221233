var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-layout"},[_c('v-card',{staticStyle:{"border-radius":"0"}},[_c('v-navigation-drawer',{staticClass:"content--text",attrs:{"color":"primary","width":_vm.drawer ? '250' : '0',"permanent":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',{staticStyle:{"padding-bottom":"0"}},[_c('v-img',{attrs:{"src":require('../assets/taleon-logo-2.png')}})],1)],1),_c('v-divider',{staticStyle:{"background":"var(--v-content-base)"}}),_c('v-list',_vm._l((_vm.navItems),function(ref,i){
var icon = ref.icon;
var text = ref.text;
var route = ref.route;
return _c('v-list-item',{key:i,staticStyle:{"text-decoration":"none"},attrs:{"to":route,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"content"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"content--text"},[_vm._v(" "+_vm._s(text)+" ")])],1)],1)}),1)],1)],1),_c('div',{staticClass:"page-layout-right"},[_c('v-app-bar',{staticClass:"content--text",attrs:{"color":"primary"}},[_c('v-app-bar-nav-icon',{attrs:{"color":"content"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(_vm._s(this.$route.name))]),_c('v-spacer'),_c('div',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.welcome))]),_c('div',{staticClass:"ml-2"}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{key:"0",on:{"click":_vm.doLogout}},[_c('v-list-item-title',[_vm._v("Çıkış Yap")])],1)],1)],1)],1),_c('div',{staticClass:"p-4"},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }