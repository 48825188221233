<template>
  <div class="page-layout">
    <v-card style="border-radius: 0">
      <v-navigation-drawer
        v-model="drawer"
        color="primary"
        class="content--text"
        :width="drawer ? '250' : '0'"
        permanent
      >
        <v-list-item>
          <v-list-item-content style="padding-bottom: 0">
            <v-img :src="require('../assets/taleon-logo-2.png')"></v-img>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="background: var(--v-content-base)" />
        <v-list>
          <v-list-item
            v-for="({ icon, text, route }, i) in navItems"
            :key="i"
            :to="route"
            link
            style="text-decoration: none"
          >
            <v-list-item-icon>
              <v-icon color="content">{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="content--text">
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <div class="page-layout-right">
      <v-app-bar color="primary" class="content--text">
        <v-app-bar-nav-icon color="content" @click.stop="drawer = !drawer" />
        <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>
        <v-spacer />
        <div class="my-auto">{{ welcome }}</div>
        <div class="ml-2" />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="0" @click="doLogout">
              <v-list-item-title>Çıkış Yap</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <div class="p-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import navItems from '@/navigation';
import axios from '@axios';
import router from '@/router';

export default {
  name: 'PageLayout',

  components: {},
  data: () => ({
    navItems,
    drawer: true
  }),
  computed: {
    welcome() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return `Merhaba ${userInfo?.name.toUpperCase()}`;
    }
  },
  methods: {
    doLogout() {
      axios
        .post('/logout')
        .then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
          router.push({ path: '/login' });
        })
        .catch((err) => {
          if (err?.response?.status !== 401) {
            this.$toast.error('Error while getting test list');
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@style/component/pageLayout.scss';
</style>
