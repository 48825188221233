import '@babel/polyfill';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

import './plugins/bootstrap-vue';

import _toastification from './plugins/toastification';
import _vue2DatePicker from './plugins/vue-2-datepicker';
import _vueApexChart from './plugins/vue-2-apexchart';

import '@/assets/style/global.scss';

Vue.config.productionTip = false;

new Vue({
  Vuex,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
