const navItems = [
  {
    icon: 'mdi-home',
    text: 'Anasayfa',
    route: '/'
  },
  {
    icon: 'mdi-alpha-t-box-outline',
    text: 'Testler',
    route: '/test'
  },
  {
    icon: 'mdi-clipboard-check-multiple-outline',
    text: 'Sınav Oluştur',
    route: '/exam'
  },
  {
    icon: 'mdi-trello',
    text: 'Raporlar',
    route: '/report'
  },
  {
    icon: 'mdi-account-group',
    text: 'Adaylar',
    route: '/candidate'
  },
  {
    icon: 'mdi-alpha-c-box-outline',
    text: 'İletişim',
    route: '/contact'
  }
];

export default navItems;
