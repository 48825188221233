<template>
  <page-layout>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Genel İstatistikler</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="data in statisticsData"
                :key="data.title"
                cols="6"
                md="3"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  :color="data.color"
                  rounded
                  class="elevation-1"
                >
                  <v-icon dark color="white" size="30">
                    {{ data.icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    {{ data.title }}
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ data.total }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">Kullanım Ayrıntıları</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <div
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 0;
                  "
                >
                  <div style="width: 90%">
                    <v-data-table
                      :headers="headers"
                      :items="reportList"
                      :items-per-page="5"
                      :loading="loading"
                      item-key="name"
                      multi-sort
                      class="elevation-2"
                    >
                      <template v-slot:[`item.candidates`]="{ item }">
                        <button class="btnExcel" @click="exportExcel(item)">
                          Excel'e Aktar
                        </button>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout';
import axios from '@axios';
import { json2excel } from 'js2excel';

export default {
  name: 'Home',

  components: {
    PageLayout
  },
  data() {
    return {
      statisticsData: [
        {
          icon: 'mdi-account-outline',
          color: 'success',
          title: 'Toplam Aday',
          total: ''
        },
        {
          icon: 'mdi-label-outline',
          color: 'warning',
          title: 'Toplam Test',
          total: ''
        },
        {
          icon: 'mdi-trending-up',
          color: 'primary',
          title: 'Toplam Sınav',
          total: ''
        }
      ],
      headers: [
        {
          text: 'Test',
          align: 'start',
          value: 'testName'
        },
        {
          text: 'Bitirilme Sayısı',
          align: 'center',
          value: 'count'
        },
        {
          text: 'Detaylar',
          align: 'end',
          value: 'candidates',
          sortable: false
        }
      ],
      loading: true,
      reportList: [],
      gytCandidate: []
    };
  },
  mounted() {
    this.statisticsList();
    this.reportsList();
  },
  methods: {
    statisticsList() {
      axios.get('/overview').then((response) => {
        this.statisticsData[0].total = response.data.totalCandidateCount;
        this.statisticsData[1].total = response.data.totalTestCount;
        this.statisticsData[2].total = response.data.totalExamCount;
      });
    },
    reportsList() {
      axios.get('/firm-test-overview').then((response) => {
        if (response.data.length !== 0) {
          response.data.forEach((i) => {
            if (i._id.type === 'generalability') {
              i.aday.forEach((candidate) => {
                this.gytCandidate.push(candidate);
              });
            } else {
              this.reportList.push({
                candidate: i.aday,
                testName: i._id.name,
                count: i.aday.length
              });
            }
          });

          this.reportList.push({
            candidate: this.gytCandidate,
            testName: 'Taleon GYT',
            count: this.gytCandidate.length
          });

          this.loading = false;
        } else {
          this.loading = false;
          // this.$toast.warning('Test Bulunamadı');
        }
      });
    },
    exportExcel(item) {
      try {
        json2excel({
          data: item.candidate,
          name: 'test-candidate-list',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        this.$toast.error(
          'Excel Aktarım Hatası.\nTablonun Dolu olduğuna emin olun'
        );
      }
    }
  }
};
</script>

<style scoped>
.btnExcel {
  padding: 6px;
  border-radius: 5px;
}

.btnExcel:hover {
  background: lightgray;
}
</style>
